import React from "react"
import "./Room.css"
import floorPlan from "../images/room-floor-plan.png"
import SelfCam from "./SelfCam"
import io from "socket.io-client"
import UserList, { UserRoomConnection } from "./UserList"

const { RTCPeerConnection, RTCSessionDescription } = window

async function call_user(
  user_socket_id: string,
  peerConnection: any,
  socket: any
) {
  console.log(`calling user ${user_socket_id}`)
  log_peer_connection(peerConnection)
  const offer = await peerConnection.createOffer()
  await peerConnection.setLocalDescription(new RTCSessionDescription(offer))
  log_peer_connection(peerConnection)
  socket.emit("call_user", {
    offer: offer,
    to: user_socket_id,
  })
}

async function call_incoming_make_answer(
  offer: any,
  peerConnection: any,
  caller_socket_id: string,
  socket: any
) {
  console.log("call incoming")
  log_peer_connection(peerConnection)
  peerConnection.setRemoteDescription(new RTCSessionDescription(offer))
  console.log("set offer")
  const answer = await peerConnection.createAnswer()
  console.log("creating answer")
  await peerConnection.setLocalDescription(new RTCSessionDescription(answer))
  console.log("set answer")
  log_peer_connection(peerConnection)

  socket.emit("make_answer", {
    answer,
    to: caller_socket_id,
  })
}

function log_peer_connection(peerConnection) {
  console.log("logging peer connection")
  console.log(peerConnection)
}

interface IState {
  active_connections: UserRoomConnection[]
}

class Room extends React.Component<any, IState> {
  name: string
  room_id: number
  user_id: number
  peerConnection: any
  // webrtc sockets are the sockets belonging to other users which are connected via webrtc
  webrtc_socket_connections: string[]
  webrtc_socket_connections_in_progress: boolean
  webrtc_socket_connections_second_time_in_progress: boolean

  constructor(props) {
    super(props)

    this.name = props.location.state.name
    this.room_id = props.match.params.id
    this.state = { active_connections: [] }
    this.peerConnection = new RTCPeerConnection()
    log_peer_connection(this.peerConnection)
    this.webrtc_socket_connections = []
    this.webrtc_socket_connections_in_progress = false
    this.webrtc_socket_connections_second_time_in_progress = false
    this.user_id = 0
    console.log("constructor")
    console.log(this.name)
    console.log(this.room_id)
  }

  public componentDidMount = () => {
    const socket = io(process.env.REACT_APP_BACK_END_URL)
    console.log(process.env.REACT_APP_BACK_END_URL)

    const connect_function = (event) => {
      console.log("connected")
      console.log(this.name)
      console.log(this.room_id)
      socket.emit("user_enters_room", {
        name: this.name,
        room: this.room_id,
        socket_id: socket.id,
      })
    }

    const new_user_function = (event) => {
      console.log("new user")
      console.log(event)
      this.setState({ active_connections: event.room.active_connections })
      if (this.user_id === 0) {
        console.log("no user id yet, find it in the user list")
        find_own_user_id(event.room.active_connections)
      }
      connect_webrtc_for_new_users(event.room.active_connections)
    }

    const find_own_user_id = (active_connections: UserRoomConnection[]) => {
      active_connections.forEach((user_room_connection: UserRoomConnection) => {
        if (user_room_connection.socket_id === socket.id) {
          this.user_id = user_room_connection.user.id
          console.log(`found my id ${this.user_id}`)
        }
      })
    }

    const connect_webrtc_for_new_users = (
      active_connections: UserRoomConnection[]
    ) => {
      // let _self:Room = this
      active_connections.forEach((user_room_connection: UserRoomConnection) => {
        console.log(`checking socket ${user_room_connection.socket_id}`)
        if (user_room_connection.socket_id === socket.id) {
          console.log("socket is myself")
        } else {
          if (
            this.webrtc_socket_connections_in_progress
            // user_room_connection.socket_id in
            // this.webrtc_socket_connections_in_progress
          ) {
            console.log("already have a webrtc chat set up in progress")
          } else if (
            user_room_connection.socket_id in this.webrtc_socket_connections
          ) {
            console.log("already have a webrtc chat set up with this socket")
          } else {
            console.log("new user found")
            console.log(`my user id ${this.user_id}`)
            if (this.user_id > user_room_connection.user.id) {
              this.webrtc_socket_connections_in_progress = true
              call_user(
                user_room_connection.socket_id,
                this.peerConnection,
                socket
              )
            }
          }
        }
      })
    }

    const call_incoming = (data) => {
      call_incoming_make_answer(
        data.offer,
        this.peerConnection,
        data.socket,
        socket
      )
    }

    const answer_incoming = (data) => {
      console.log("answer incoming")
      this.peerConnection.setRemoteDescription(
        new RTCSessionDescription(data.answer)
      )
      if (this.webrtc_socket_connections_second_time_in_progress === false) {
        // Re call the user for a second time to exchange ice candidates
        console.log("calling user for the second time")
        this.webrtc_socket_connections_second_time_in_progress = true
        call_user(data.socket, this.peerConnection, socket)
      }
    }

    const set_up_remote_video = ({ streams: [stream] }) => {
      console.log("connection made")
      log_peer_connection(this.peerConnection)
      const remoteVideo: HTMLVideoElement = document.getElementById(
        "remote-video"
      ) as HTMLVideoElement
      if (remoteVideo) {
        console.log("setting stream on remote video")
        remoteVideo.width = 400
        remoteVideo.height = 300
        remoteVideo.srcObject = stream
        // remoteVideo.width = remoteVideo.videoWidth
        // remoteVideo.height = remoteVideo.videoHeight
      }
    }

    const ice_change = () => {
      console.log(
        "ICE Candidate State: " + this.peerConnection.iceConnectionState
      )
      console.log(
        "ICE Gathering State: " + this.peerConnection.iceGatheringState
      )
    }

    this.peerConnection.ontrack = set_up_remote_video.bind(this)
    this.peerConnection.onicegatheringstatechange = ice_change.bind(this)
    this.peerConnection.oniceconnectionstatechange = ice_change.bind(this)

    // find_own_user_id.bind(this)
    connect_webrtc_for_new_users.bind(this)
    socket.on("connect", connect_function.bind(this))
    socket.on("new_user_list", new_user_function.bind(this))
    socket.on("call_incoming", call_incoming.bind(this))
    socket.on("answer_incoming", answer_incoming.bind(this))
  }

  render() {
    console.log(this.props)
    return (
      <div>
        <div className="grid-container">
          <div className="grid-item other-view1">
            <div>
              <video autoPlay id="remote-video"></video>
            </div>
          </div>
          <div className="grid-item other-view2">Other Cam 2</div>
          <div className="grid-item other-view3">Other Cam 3</div>
          <div className="grid-item room-layout">
            Room Layout
            <img src={floorPlan} alt="floor plan" id="floor-plan-image" />
          </div>
          <div className="grid-item self-forward">Self Forward</div>
          <div className="grid-item self-cam">
            <SelfCam peerConnection={this.peerConnection} />
          </div>
          <div className="grid-item room-info">
            Room {this.room_id}
            <p>Welcome {this.name}</p>
            <p>Users in room</p>
            <p>
              <UserList active_connections={this.state.active_connections} />
            </p>
          </div>
          <div className="grid-item room-chat">Chat</div>
        </div>
      </div>
    )
  }
}

export default Room
