import React from "react"
import "./SelfCam.css"

interface IProps {
  peerConnection: RTCPeerConnection
}

class SelfCam extends React.Component<IProps> {
  componentDidMount() {
    const startVideoRender = (stream) => {
      console.log("start self video render")
      const video: any = document.getElementById("self-video")
      video.width = 400
      video.height = 300
      const filteredStream = stream
      filteredStream
        .getTracks()
        .forEach((track) =>
          this.props.peerConnection.addTrack(track, filteredStream)
        )
    }

    navigator.getUserMedia(
      { video: true, audio: true },
      (stream) => {
        const video: any = document.getElementById("self-video")
        if (video) {
          console.log("set local stream")
          video.srcObject = stream

          video.addEventListener("canplay", startVideoRender(stream))
        }
        // stream
        //   .getTracks()
        //   .forEach((track) => this.props.peerConnection.addTrack(track, stream))
      },
      (error) => {
        console.warn(error.message)
      }
    )
  }

  render() {
    return (
      <div>
        <video autoPlay muted id="self-video"></video>
      </div>
    )
  }
}

export default SelfCam
