import React from "react"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"

interface IProps {
  history: any
}

interface IState {
  room_id_text: string
  name_text: string
}

class EnterRoomForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
    this.state = { room_id_text: "", name_text: "" }

    this.handleRoomIdChange = this.handleRoomIdChange.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleRoomIdChange(event) {
    this.setState({ room_id_text: event.target.value })
  }

  handleNameChange(event) {
    this.setState({ name_text: event.target.value })
  }

  handleSubmit(event) {
    console.log("/room/" + this.state.room_id_text)
    this.props.history.push({
      pathname: "/room/" + this.state.room_id_text,
      state: { name: this.state.name_text },
    })
    event.preventDefault()
  }

  render() {
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group controlId="roomEnter">
          <Form.Control
            placeholder="Enter Room Id"
            type="text"
            value={this.state.room_id_text}
            onChange={this.handleRoomIdChange}
          />
        </Form.Group>
        <Form.Group controlId="nameEnter">
          <Form.Control
            placeholder="Enter Name"
            type="text"
            value={this.state.name_text}
            onChange={this.handleNameChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Enter
        </Button>
      </Form>
      // <form onSubmit={this.handleSubmit}>
      //   <label>
      //     Name:
      //     <input type="text" value={this.state.value} onChange={this.handleChange} />
      //   </label>
      //   <input type="submit" value="Submit" />
      // </form>
    )
  }
}

export default EnterRoomForm
