import React from "react"
import Room from "./components/Room"
import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import Landing from "./components/Landing"
import { Route } from "react-router-dom"

interface IState {
  page: string
  room_id?: number
}

class App extends React.Component<{}, IState> {
  constructor(props) {
    super(props)
    this.state = { page: "landing" }
  }

  switch_page = (new_page, room_id) => {
    this.setState({ page: new_page, room_id: room_id })
  }

  render() {
    return (
      <div>
        <div>
          <Route exact path="/" component={Landing} />
        </div>
        <div>
          <Route path="/room/:id" component={Room} />
        </div>
      </div>
    )
  }
}

export default App
