import React from "react"
import EnterRoomForm from "./EnterRoomForm"
import { useHistory } from "react-router-dom"

function Landing(switch_page) {
  let history = useHistory()
  console.log(process.env.NODE_ENV)
  console.log(process.env.REACT_APP_BACK_END_URL)
  return (
    <div className="Landing">
      <header className="App-header">
        <p>Welcome to the Escape Room</p>
        <EnterRoomForm history={history}></EnterRoomForm>
      </header>
    </div>
  )
}

export default Landing
