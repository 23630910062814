import React from "react"
import "./Room.css"

export interface User {
  username: string
  id: number
}

export interface UserRoomConnection {
  socket_id: string
  user: User
}

interface IProps {
  active_connections: UserRoomConnection[]
}

class UserList extends React.Component<IProps, any> {
  render() {
    const userListItems = this.props.active_connections.map(
      (user_room_connection) => <li>{user_room_connection.user.username}</li>
    )
    return <ul>{userListItems}</ul>
  }
}

export default UserList
